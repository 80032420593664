// Script powered by GitHub Copilot Chat
if(document.querySelector("div#termite_species")) {
  let termiteTreatments = document.querySelectorAll("input[data-treatement_type='Barrières chimiques après construction curatif'], input[data-treatement_type='Pièges contrat de surveillance'], input[data-treatement_type='Pièges contrat curatif']");
  let termiteSpeciesSelect = document.querySelector("select[name='work_site[termiteSpecies]']");
  let termiteSpeciesBlock = document.querySelector("div#termite_species");

  // Function to check if any termite treatment checkbox is checked
  function checkTermiteTreatments() {
    let isChecked = Array.from(termiteTreatments).some(checkbox => checkbox.checked);
    if (isChecked) {
      // If any checkbox is checked, display the termite species selector and add the required attribute
      termiteSpeciesBlock.style.display = "block";
      termiteSpeciesSelect.required = true;
    } else {
      // If no checkbox is checked, hide the termite species selector, remove the required attribute and reset value at null
      termiteSpeciesBlock.style.display = "none";
      termiteSpeciesSelect.required = false;
      termiteSpeciesSelect.selectedIndex = 0;
    }
  }
  termiteTreatments.forEach(checkbox => {
    checkbox.addEventListener("change", checkTermiteTreatments);
  });

  // Initialize state of termite species selector
  checkTermiteTreatments();
}