// Init BootStrap tooltips
$(function () {
  $('[data-toggle="tooltip"]').tooltip();
});

const passwordField = document.querySelector(".password-input");
const showHidePasswordTrigger = document.querySelector(".show-hide-password-trigger");
showHidePasswordTrigger.addEventListener("click", (e) => {
  e.preventDefault();
  if (passwordField.getAttribute("type") === "password") {
    passwordField.setAttribute("type", "text");
    showHidePasswordTrigger.innerHTML = 'Masquer le mot de passe';
  } else {
    passwordField.setAttribute("type", "password");
    showHidePasswordTrigger.innerHTML = 'Afficher le mot de passe';
  }
});
