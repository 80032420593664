if(document.querySelector("a.btn.btn-block.btn-success.export.validate")) {
    document.querySelector("a.btn.btn-block.btn-success.export.validate").addEventListener("click", (evt) => {
            if (!confirm("Voulez vous vraiment valider le trimestre " + evt.currentTarget.dataset.quarterlabel + " ?")) {
                evt.preventDefault();
                return
            }
            if (!confirm("Attention, une fois validé, vous ne pourrez plus modifier ou ajouter de chantier pour le timestre " + evt.currentTarget.dataset.quarterlabel + ". Souhaitez-vous continuer ? ")) {
                evt.preventDefault();
            }
        }
        , false);
}

if (document.querySelector("a.btn.btn-danger[name='delete-worksite']")) {
    document.querySelector("a.btn.btn-danger[name='delete-worksite']").addEventListener("click", (evt) => {
            if (!confirm("Voulez vous vraiment supprimer ce chantier ?")) {
                evt.preventDefault();
            }
        }
        , false);
}
if (document.querySelector("a.btn.btn-danger[name='devalidate']")) {
    document.querySelector("a.btn.btn-danger[name='devalidate']").addEventListener("click", (evt) => {
            if (!confirm("Voulez vous vraiment dévalider le trimestre précédant ?")) {
                evt.preventDefault();
            }
        }
        , false);
}