// Icons => FontAwesome
import 'font-awesome/css/font-awesome.min.css';

// Select2
// import 'select2/dist/js/select2.min.js';
// import 'select2/dist/js/i18n/fr.js';
// import 'select2/dist/css/select2.css';

require('./scss/main.scss');

import './js/confirmation.js';
import './js/filterWorksites.js';
import './js/termiteSpeciesForm.js';


// import 'bootstrap/js/dist/alert.js';
// import 'bootstrap/js/dist/button.js';
import 'bootstrap/js/dist/util.js';
// import 'bootstrap/js/dist/carousel.js';
// import 'bootstrap/js/dist/collapse.js';
// import 'bootstrap/js/dist/dropdown.js';
// import 'bootstrap/js/dist/index.js';
// import 'bootstrap/js/dist/modal.js';
// import 'bootstrap/js/dist/popover.js';
// import 'bootstrap/js/dist/scrollspy.js';
// import 'bootstrap/js/dist/tab.js';
import 'bootstrap/js/dist/tooltip.js';

import './js/app.js';

if (process.env.NODE_ENV === 'development') {
  require('./twig-hmr.js');
}

if (module.hot) {
    module.hot.accept();
}
